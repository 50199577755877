import * as React from "react";

import Button from "../components/Button/Button.jsx";
import Icon from "../components/Icon/Icon.jsx";
import Group from "../components/Group/Group.jsx";
// import Link from "../components/Link/Link.jsx";
import Section from "../components/Section/Section.jsx";
import Text from "../components/Text/Text.jsx";
import TextInput from "../components/TextInput/TextInput.jsx";
import Select from "../components/Select/Select.jsx";
import CurrencyInput from 'react-currency-input-field';

class CartForm extends React.Component {
  static CURRENCIES = [
    { value: "aud", label: "AUD" },
    { value: "cad", label: "CAD" },
    { value: "czk", label: "CZK" },
    { value: "eur", label: "EUR" },
    { value: "gbp", label: "GBP" },
    { value: "nzd", label: "NZD" },
    { value: "sgd", label: "SGD" },
    { value: "usd", label: "USD" },
    { value: "dkk", label: "DKK" },
    { value: "sek", label: "SEK" },
  ];

  constructor(props) {
    super(props);
    this.state = {
      displayAmount: this.props.chargeAmount>0?(this.props.chargeAmount / 100).toFixed(2):'',
    };
  }

  handleAddItem = () => {
    //const { itemDescription, chargeAmount, gstIncluded, currency, quantity } = this.state;

    // if (isNaN(chargeAmount) || chargeAmount <= 0) {
    //   alert("Please enter a valid charge amount.");
    //   return;
    // }
    const taxAmount = this.props.gstIncluded ? (this.props.chargeAmount / 11) : 0;
    const itemDescription = this.props.itemDescription
    const chargeAmount = this.props.chargeAmount
    const gstIncluded = this.props.gstIncluded
    const currency = this.props.currency
    const quantity = this.props.quantity
    const newItem = { itemDescription, chargeAmount, taxAmount, gstIncluded, currency, quantity };  
    this.props.addItemToCart(newItem);
    this.setState({
      itemDescription: "",
      chargeAmount: "",
      taxAmount: 0,
      gstIncluded: false,
      currency: CartForm.CURRENCIES[0].value,
      quantity: 1,
      receiptEmail: "",
      displayAmount: "",
    });
  };

  handleDisplayAmountChange = (value) => {
    const chargeAmount = Math.round(parseFloat(value) * 100);
    this.setState({ displayAmount: value });
    this.props.onChangeChargeAmount(chargeAmount);
  };

  handleRemoveItem = (index) => {


  }

  render() {
    return (
      <>
        <Group direction="column" spacing={0}>
          <Section position="first" backgroundColor="#A5A3D9">
            <Text size={16} color="dark">
              Add Items
            </Text>
          </Section>
          <Section position="middle">
            <Group direction="column">
              <Group
                direction="row"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size={12} color="dark" className="label">
                  Item description
                </Text>
                <TextInput
                  className="left-align"
                  placeholder="Enter an Item"
                  value={this.props.itemDescription}
                  onChange={this.props.onChangeItemDescription}
                  ariaLabel="Item description"                  
                />
              </Group>
              <Group
                direction="row"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size={12} color="dark"  className="label">
                  Charge amount
                </Text>
                <CurrencyInput
                  className="TextInput left-align"
                  id="input-charge-amount"
                  name="input-chargeamount"
                  placeholder="Enter an amount"                  
                  prefix="$"
                  // defaultValue={this.state.displayAmount}
                  value={this.state.displayAmount}
                  decimalScale={2}
                  decimalSeparator="."
                  onValueChange={this.handleDisplayAmountChange}
                />
              </Group>
              <Group
                direction="row"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size={12} color="dark"  className="label">
                  Includes GST
                </Text>
                <input
                  type="checkBox"
                  checked={this.props.gstIncluded}
                  onChange={this.props.onGSTchange}
                ></input>
              </Group>
              {/* <Group
                direction="row"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size={12} color="dark">
                  Tax amount
                </Text>
                {/* <TextInput
                  value={this.props.taxAmount}
                  onChange={this.props.onChangeTaxAmount}
                  ariaLabel="Tax amount"
                /> */}
              {/* <CurrencyInput
                  className="TextInput"
                  id="input-tax-amount"
                  name="input-taxamount"
                  placeholder="Please enter an amount"
                  // value={parseFloat(this.props.chargeAmount) / 100}
                  prefix="$"
                  defaultValue={parseFloat(this.props.taxAmount) / 100}
                  
                  decimalScale={2}
                  decimalSeparator="."                  
                  onValueChange={(value, name, values) => {
                    this.props.onChangeTaxAmount(value)
                    console.log(value, name, values)
                  }}
                />
              </Group> */}
              <Group
                direction="row"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size={12} color="dark"  className="label">
                  Currency
                </Text>
                <Select
                  items={CartForm.CURRENCIES}
                  value={CartForm.CURRENCIES[0]}
                  onChange={this.props.onChangeCurrency}
                  ariaLabel="Currency"
                />
              </Group>

              <Button
                color="white"
                onClick={this.handleAddItem}
                // disabled={!this.state.itemDescription || !this.state.chargeAmount}
                justifyContent="left"
              >
                <Group direction="row">
                  <Icon icon="list" />
                  <Text color="blue" size={14}>
                    Add item to cart
                  </Text>
                </Group>
              </Button>


            </Group>
                       
            {/* </Section> */}
            {/* <Section position="last">
            <Text size={12} color="lightGrey">
              Test payment responses{" "}
              <Link
                href="https://stripe.com/docs/terminal/testing"
                text="using amounts"
                newWindow
              />
              .
            </Text>
          </Section> */}
          </Section>
          <Section position="middle" backgroundColor="#F0EDFF">
            <Text size={16} color="dark">
              Cart Items
            </Text>
            {this.props.cartItems.map((item, index) => (
              <Group
                key={index}
                direction="row"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Button
                  color="red"
                  onClick={() => this.props.removeItemFromCart(index)}
                >
                  <Icon icon="cancel" />
                  {/* <Text color="white" size={12}>
                    Remove
                  </Text> */}
                </Button>
                <Text size={12} color="dark">
                  {item.itemDescription} - ${(item.chargeAmount / 100).toFixed(2)} {item.currency}  - Tax: ${(item.taxAmount / 100).toFixed(2)}
                </Text>
              </Group>
            ))}
            <Group
              direction="row"
              alignment={{
                justifyContent: "flex-end",
                alignItems: "center"
              }}
              style={{ width: "100%" }}
            >
              <Text size={12} color="dark">
                Total: ${(this.props.cartItems.reduce((acc, item) => acc + item.chargeAmount / 100, 0)).toFixed(2)}
              </Text>
            </Group>
            <Group
              direction="row"
              alignment={{
                justifyContent: "flex-end",
                alignItems: "center"
              }}
              style={{ width: "100%" }}
            >
              <Text size={12} color="dark">
                Tax: ${(this.props.cartItems.reduce((acc, item) => acc + item.taxAmount / 100, 0)).toFixed(2)}
              </Text>
            </Group>

            <Button
              color="white"
              onClick={this.props.clearCart}
              // disabled={this.props.workFlowDisabled}
              justifyContent="left"
            >
              <Group direction="row">
                <Icon icon="list" />
                <Text color="blue" size={14}>
                  Clear the Cart
                </Text>
              </Group>
            </Button>

            {/* <Button
                color="white"
                onClick={this.props.onClickUpdateLineItems}
                disabled={this.props.workFlowDisabled}
                justifyContent="left"
              >
                <Group direction="row">
                  <Icon icon="list" />
                  <Text color="blue" size={14}>
                    Update line items and totals
                  </Text>
                </Group>
              </Button> */}
          </Section>
          <Section position="last" backgroundColor="#A5A3D9">
              <Group
                direction="row"
                alignment={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text size={12} color="dark" className="label">
                  Receipt Email
                </Text>
                <TextInput
                  className="left-align"
                  placeholder="Enter an email"
                  value={this.props.receiptEmail}
                  onChange={this.props.onChangeReceiptEmail}
                  ariaLabel="Email"
                />
              </Group>

              </Section>

        </Group>
      </>
    );
  }
}

export default CartForm;
