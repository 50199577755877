import * as React from "react";
import Button from "../components/Button/Button.jsx";
import Group from "../components/Group/Group.jsx";
import Section from "../components/Section/Section.jsx";
import Text from "../components/Text/Text.jsx";

class BackendURLForm extends React.Component {  
  constructor(props) {
    super(props);

    let localstorageUrlCache = window.localStorage.getItem("terminal.backendUrl");
    this.state = {
      backendURL: localstorageUrlCache ? localstorageUrlCache : "",
      destination: ""
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);    
    switch (url.hostname) {
      case "localhost":        
        this.setState({ backendURL: "https://stripebackend.agdigital.com.au:4567" });
        this.state.destination = "Arresti Test" 
        break;
      case "terminal.atiaustralia.edu.au":
        this.setState({ backendURL: "https://stripeserver.atiaustralia.edu.au:4567" });        
        this.state.destination = "ATI Australia"
        break;
      case "terminal.ansic.com.au":
        this.setState({ backendURL: "https://stripeserver.ansic.com.au:4567" });
        this.state.destination = "ANSIC Security"
        break
      default:
        this.setState({ backendURL: "https://stripebackend.agdigital.com.au:4567"});
        this.state.destination = "Arresti Test" 
    }
    this.props.onSetDestination(this.state.destination)
  }

  onFormInitialize = (event) => {
    event.preventDefault();
    this.props.onSetBackendURL(this.state.backendURL.trim());
    this.props.onSetDestination(this.state.destination)
  };

  onChangeBackendURL = (event) => {
    this.setState({ backendURL: event.target.value });
  };

  render() {
    const { backendURL } = this.state;
    return (
      <Section>
        <form onSubmit={this.onFormInitialize}>
          <Group direction="column" spacing={18}>
            <Text size={16} color="dark">
              Connect to {this.state.destination}
            </Text>

            <Group direction="column">
              <Group direction="row" alignment={{ justifyContent: "space-between" }}>
                <Button
                  disabled={backendURL === ""}
                  color="primary"
                  type="submit"
                >
                  <Text color="white" size={14}>
                    Connect
                  </Text>
                </Button>
              </Group>
            </Group>
          </Group>
        </form>
      </Section>
    );
  }
}

export default BackendURLForm;
