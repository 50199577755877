import * as React from "react";
import { css } from "emotion";
import Button from "../components/Button/Button.jsx";
import Group from "../components/Group/Group.jsx";
import Icon from "../components/Icon/Icon.jsx";
import Section from "../components/Section/Section.jsx";
import Text from "../components/Text/Text.jsx";

class ConnectionInfo extends React.Component {
  state = {
    selectedBackendURL: this.props.backendURL || "",
    destination: this.props.destination || "No destination provided"
  };

  handleURLChange = (event) => {
    this.props.onClickDisconnect();
    this.props.onSetBackendURL(null);
    this.setState({ selectedBackendURL: event.target.value });
    this.props.onSetBackendURL(event.target.value);
  };

  onChangeBackendURL = () => {
    this.props.onClickDisconnect();
    this.props.onSetBackendURL(null);
  };

  render() {
    const { reader, onClickDisconnect } = this.props;
    const { selectedBackendURL, destination } = this.state;


    return (
      <Group direction="column" spacing={0}>
        <Section position="first">
          <Group
            direction="row"
            alignment={{
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Group direction="row">
              <Icon icon="lock" />
              <div
                className={css`
                  width: 130px;
                `}
              >
                <Text color="dark" size={14}>
                  {this.state.destination}
                  </Text>
                {/* <select
                  value={selectedBackendURL}
                  onChange={this.handleURLChange}
                  className={css`
                    width: 100%;
                  `}
                >
                  <option value="https://stripebackend.agdigital.com.au:4567">Arresti Test</option>
                  <option value="https://stripeserver.atiaustralia.edu.au:4567">ATI Live</option>
                  <option value="https://stripeserver.ansic.com.au:4567">ANSIC Live</option>
                </select> */}
              </div>
            </Group>
            {/* <Button color="text" onClick={this.onChangeBackendURL}>
              Change
            </Button> */}
          </Group>
        </Section>
        <Section position="last">
          {reader ? (
            <Group
              direction="row"
              alignment={{
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Group direction="row">
                <span>
                  <Icon icon="keypad" />
                </span>
                <Text truncate color="dark" size={14}>
                  {reader.label}
                </Text>
              </Group>
              <Button color="text" onClick={onClickDisconnect}>
                Disconnect
              </Button>
            </Group>
          ) : (
            <Group direction="row">
              <span>
                <Icon icon="keypad" />
              </span>
              <Text color="lightGrey" size={14}>
                No reader connected
              </Text>
            </Group>
          )}
        </Section>
      </Group>
    );
  }
}

export default ConnectionInfo;
